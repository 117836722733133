import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Video = ({ videoSrcURL, videoTitle }) => (
  <StyledVideo>
    <iframe
      src={`${videoSrcURL}${videoSrcURL.includes('vimeo') ? '?dnt=1' : ''}`}
      title={videoTitle}
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </StyledVideo>
);

const StyledVideo = styled.div`
  position: relative;
  padding-top: 56.25%;
  height: 0;
  overflow: hidden;
  width: 100%;
  height: auto;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

Video.propTypes = {
  videoSrcURL: PropTypes.string,
};

export default Video;

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { media } from '../utils/media-queries';

import Layout from '../components/layout';
import SEO from '../components/seo';
import RichText from '../components/richtext';
import Video from '../components/video';
import { useEventListener } from '../hooks/useEventListener';
import { useClientSide } from '../hooks/useClientSide';

const VideoPage = ({ data }) => {
  const { backgroundColor, videos } = data.videos.nodes[0];

  const ref = useRef();
  const isClient = useClientSide();

  const keyScroll = e => {
    if (ref.current === null) return;

    const speed = 10;
    if (e.keyCode === 40 || e.keyCode === 39) ref.current.scrollLeft += speed;
    if (e.keyCode === 38 || e.keyCode === 37) ref.current.scrollLeft -= speed;
  };

  useEventListener('keydown', keyScroll, isClient && document);

  return (
    <Layout color={backgroundColor.hex}>
      <SEO siteTitle="video" />
      <StyledContent className="grid" ref={ref}>
        {videos.map((video, i) => (
          <StyledVideoWrapper key={i}>
            <Video videoSrcURL={video.url} videoTitle={video.title} />
            <p className="title">{video.title}</p>
            <div className="text">
              <RichText blocks={video._rawText} />
            </div>
          </StyledVideoWrapper>
        ))}
      </StyledContent>
    </Layout>
  );
};

const StyledContent = styled.section`
  overflow-x: hidden;

  @media ${media.M} {
    height: 80vh;
    overflow-y: scroll;
    padding-right: 10vh;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const StyledVideoWrapper = styled.figure`
  .title {
    padding: var(--spacing-XXS) var(--spacing-XXS) 0 var(--spacing-XXS);
    text-transform: uppercase;
  }

  .text {
    width: var(--spacing-XXL);
    padding: var(--spacing-XXS);
  }

  @media ${media.M} {
    .title {
      padding: var(--spacing-XXS) var(--spacing-XXS) 0 0;
    }

    .text {
      padding: var(--spacing-XXS) var(--spacing-XXS) var(--spacing-XXS) 0;
    }
  }
`;

VideoPage.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query Videos {
    videos: allSanityVideosPage {
      nodes {
        backgroundColor {
          hex
        }
        videos {
          url
          title
          _rawText
        }
      }
    }
  }
`;

export default VideoPage;
